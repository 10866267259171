import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { mapBlogs } from '../../lib/utility';
import { img, imgBrand, alt, isBe } from '../../lib/brand';
import BlogRoll from '../../components/BlogRoll';

import Layout from '../../components/Layout';
import Header from '../../components/Header';
import ProductHero from '../../components/ProductHero';
import FindLO from '../../components/FindLO';
import ProductDetails from '../../components/ProductDetails';
import styles from './styles.module.less';

var background = img('reverseMortgagebg.jpg');
var icon = imgBrand('typesImg/Icons_ReverseMortgage_active.png');

const ReverseMortgage = () => {
   const data = useStaticQuery(graphql`
      query ReverseMortgageQuery {
         corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: { type: { eq: "corporate" }, tags: { in: ["Reverse Mortgage"] } }
         ) {
            edges {
               node {
                  id
                  html
                  slug
                  title
                  date
                  feature
                  categories
                  tags
                  image
                  author {
                     slug
                     profile {
                        name
                     }
                     gallery {
                        photo
                     }
                  }
                  date
                  feature
               }
            }
         }
      }
   `);
   const header = <Header active={['reverse-mortgage']} />;
   const intro = 'Reverse Mortgage';
   const subhead = (
      <p>
         Access your home&apos;s equity
         <br /> with no monthly mortgage payments.
      </p>
   );

   const { corpBlogs } = data;
   const { edges: corpBlobPosts = [] } = corpBlogs || {};
   const corporateBlogs = mapBlogs(corpBlobPosts);

   return (
      <Layout hasHero={true} path="/reverse-mortgage" header={header}>
         <Helmet>
            <title>{alt('Reverse Mortgage - Reverse Mortgage Solutions')}</title>
            <meta
               name="description"
               content="A reverse mortgage is a type of home loan that allows you convert a portion of the equity in your home into cash Want to find out if you qualify? Contact us today."
            />
         </Helmet>
         <ProductHero
            customPhoto={background}
            icon={icon}
            header={intro}
            subhead={subhead}
            alt={alt('Reverse Mortage')}
         />
         <section className={styles.Intro}>
            <div className={styles.Container}>
               <h2>
                  Whether using HUD’s Home Equity Conversion Mortgage (HECM) or Bay Equity’s proprietary product line of
                  reverse mortgages, we have solutions.
               </h2>
               <ul className={styles.firstList}>
                  <li>
                     <p>Available to seniors 60 and older (62 in TX and NC)</p>
                  </li>
                  <li>
                     <p>No monthly mortgage payments</p>
                  </li>
                  <li>
                     <p>
                        Flexible availability of funds (monthly payments, line of credit, cash, or a blend of the
                        options to fit your needs)
                     </p>
                  </li>
                  <li>
                     <p>No maximum property value</p>
                  </li>
                  <li>
                     <p>HUD and non-HUD approved condominiums available</p>
                  </li>
               </ul>

               <h2 className={styles.introSubHead}>
                  Purchase a new primary residence or access the equity in your current home
               </h2>
               <h2>Use the funds for any purpose, including:</h2>
               <ul>
                  <li>
                     <p>Supplement to your monthly income with tax-free proceeds</p>
                  </li>
                  <li>
                     <p>Access to cash for sudden expenses</p>
                  </li>
                  <li>
                     <p>Eliminate your current monthly mortgage payment</p>
                  </li>
                  <li>
                     <p>Making repairs or improvements to your home</p>
                  </li>
                  <li>
                     <p>Purchase of a second residence, automobile, or RV</p>
                  </li>
                  <li>
                     <p>Pay for needed medical and dental care and prescription medications</p>
                  </li>
                  <li>
                     <p>Pay for in-home health care</p>
                  </li>
                  <li>
                     <p>Travel</p>
                  </li>
               </ul>
            </div>
         </section>
         <ProductDetails title={'Our Code of Ethics for reverse mortgages'} border={true}>
            <p>
               Bay Equity is a proud member in good standing with the National Reverse Mortgage Lenders Association and
               subscribes to it&apos;s comprehensive{' '}
               <a
                  href="http://services.nrmlaonline.org/NRMLA_Documents/NRMLA%20Code%20of%20Ethics-FINAL.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}>
                  Code of Ethics.
               </a>{' '}
            </p>
         </ProductDetails>
         <ProductDetails title={'How much money can I receive and how?'} border={true}>
            <p>
               Reverse Mortgages are highly customizable to fit your situation. The amount of money available is based
               on the age of the younger borrower, the value of the home, and current interest rates. The proceeds must
               first be used to pay off any existing loans or liens against the property and the remaining funds can be
               disbursed in any number of ways. Some seniors choose to take a guaranteed payment for life, others choose
               to take it for a specific term. There is also the ability to draw cash at closing or leave the funds in a
               growing line of credit for future use. The more common way is to blend these choices.
            </p>
         </ProductDetails>
         <ProductDetails title={'Will I give up the ownership of my home?'} border={true}>
            <p>
               Like traditional home loans or lines of credit, you don’t give up the ownership of your home. A reverse
               mortgage is simply a lien against your home. You are free to refinance, pay off the loan without penalty
               should you wish (although no monthly payments are required) or sell the home should your needs change.
               You will still be able to pass the home to your heirs. In the event that more is owed on the loan balance
               than the future value of the home, neither you nor your estate will have to make up the difference.
            </p>
         </ProductDetails>
         <ProductDetails title={'Who pays the property taxes and insurance on the home?'} border={true}>
            <p>
               Because you will continue to own the home, you are responsible for payment of property taxes, homeowner’s
               insurance, Homeowners Association due (if any) will continue to be your responsibility. You may elect to
               have a portion of the funds set aside for future payment of taxes and insurance.
            </p>
         </ProductDetails>
         <ProductDetails title={'When does the loan come due?'} border={true}>
            <p>
               The loan will continue as long as at least one borrower lives in the property as a primary residence, or
               in the case of a non-borrowing spouse, while that spouse remains in the home. Otherwise, the loan will
               come due only when the homeowner fails to pay property taxes, insurance, property related charges, or
               fails to comply with the terms of the financing instruments.
            </p>
         </ProductDetails>
         <ProductDetails
            title={
               'Are the funds I receive taxable? Does receipt of the money affect my Social Security, Medicaid, or retirement income?'
            }
            border={true}>
            <p>
               Because the funds are the proceeds of a loan, they are not considered taxable income and will not affect
               Social Security, Medicaid, or pension income. Some forms of subsidized Medicaid have restrictions on cash
               on hand so we will work with you to structure the loan in such a way as to avoid any reduction in
               services.
            </p>
         </ProductDetails>
         <ProductDetails
            title={'Should I consider a reverse mortgage even if I don’t “need” the funds today?'}
            border={true}>
            <p>
               One of the ways that seniors use the reverse mortgage is by leaving the funds available for future use in
               a growing line of credit. Any unused funds available in the line will grow over time, resulting in a
               larger cash amount in the future. Some seniors use this line of credit to offset investment risks, fund
               short-term purchases, or to simply let the line grow in value as a hedge against future expenses.
               Strategically used, a reverse mortgage can serve as a backstop against portfolio declines or exhaustion.
            </p>
         </ProductDetails>
         <ProductDetails title={'Reverse Mortgage Resources'}>
            <ul>
               <li>
                  <p>
                     <a
                        href="https://www.ncoa.org/wp-content/uploads/FINAL-2018-NCOA-Reverse-Mortgage-Booklet.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}>
                        National Council on Aging
                     </a>
                  </p>
               </li>
               <li>
                  <p>
                     <a
                        href="https://www.nrmlaonline.org/wp-content/uploads/2019/01/NRMLA_Reverse-Mortgage-Self-Evaluation_FINAL.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}>
                        National Reverse Mortgage Lenders Association: Self Evaluation
                     </a>
                  </p>
               </li>
               <li>
                  <p>
                     <a
                        href="https://www.nrmlaonline.org/wp-content/uploads/2018/03/NRMLA_HomeToolkit_032218.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}>
                        National Reverse Mortgage Lenders Association: Toolkit for Choosing the Right Home Environment
                     </a>
                  </p>
               </li>
               <li>
                  <p>
                     <a
                        href="https://www.nrmlaonline.org/wp-content/uploads/2018/06/NRMLA_RecognizeReport_TriFold_Brochure.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}>
                        National Reverse Mortgage Lenders Association: Recognize &amp; Report Financial Abuse
                     </a>
                  </p>
               </li>
               <li>
                  <p>
                     <a
                        href="https://www.nrmlaonline.org/wp-content/uploads/2019/01/What-Do-I-Do-When-My-Loan-is-Due.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}>
                        National Reverse Mortgage Lenders Association: What Do I Do When My Loan is Due?
                     </a>
                  </p>
               </li>
            </ul>
         </ProductDetails>
         {isBe && (
            <>
               <FindLO />
               {corporateBlogs.length >= 3 && (
                  <BlogRoll
                     corporateBlogs={corporateBlogs}
                     blogs={corporateBlogs}
                     showBlogs={true}
                     word="our"
                     url="reverse-mortgage"
                  />
               )}
            </>
         )}
         <div className={styles.disclosure}>
            This advertising material is not from HUD or the HFA, and this document is not approved by HUD or by the FHA
            or any other Federal Agency
         </div>
      </Layout>
   );
};

export default ReverseMortgage;
