import React, { Component } from 'react';
import { img } from '../../lib/brand';
import Accent from '../Accent';
import styles from './styles.module.less';

var arrow = img('dropdownarrow.png', 'ProductDetails');

class ProductDetails extends Component {
   constructor(props) {
      super(props);
      this.state = {
         active: false
      };
   }

   handleClick = ({ target }) => {
      if (target && target.href) {
         // don't change state on link click
         return;
      }
      this.setState({
         active: !this.state.active
      });
   };

   render() {
      //border prop used to toggle bottom border on & off

      const { border } = this.props;

      return (
         <div
            className={styles.productDetailsBorder}
            onClick={this.handleClick}
            style={border ? {} : { border: 'none' }}>
            <button className={this.state.active ? `${styles.arrow__up}` : `${styles.arrow}`}>
               <img className={styles.disclosureArrow} src={arrow} alt="" />
            </button>
            <div className={styles.copy}>
               <div className={styles.titleBox}>
                  <h2 className={styles.productTitle}>{this.props.title}</h2>
               </div>
               <div className={this.state.active ? `${styles.children}` : `${styles.hidden}`}>
                  <Accent align="left" />
                  {this.props.children}
               </div>
            </div>
         </div>
      );
   }
}

export default ProductDetails;
